<template>
  <div>
    <div class="c-section">
      <p>アプリへのご質問、お問い合わせはこちらからお願いいたします。</p>
    </div>
    <div class="c-section">
      <ValidationObserver
        ref="observerRef"
        tag="form"
        class="c-form"
        v-slot="{ valid }">
        <BaseInput
          v-model="form.email"
          type="email"
          label="メールアドレス"
          required
          placeholder="メールアドレスを入力してください"
          :vee-props="veeProps.email" />

        <BaseSelect
          v-model.number="form.category"
          label="お問い合わせカテゴリ"
          :items="categories"
          required
          :vee-props="veeProps.category" />

        <BaseTextarea
          v-model="form.body"
          label="お問い合わせ内容"
          required
          placeholder="お問い合わせ内容を入力してください"
          :vee-props="veeProps.body" />

        <BaseCheckbox v-model="form.agreement" :vee-props="veeProps.agreement">
          <!-- prettier-ignore -->
          <a :href="$customUrlScheme.page('standalone', 'url=/terms')">利用規約</a>
          に同意する
        </BaseCheckbox>

        <Button
          :style-type="valid ? 'secondary' : 'disabled'"
          fluid
          class="p-contact__action"
          @click="post">
          送信する
        </Button>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /** @type {{ label: string; value: number }[]} */
    categories: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    form: {
      email: '',
      category: '',
      body: '',
      agreement: false
    }
  }),

  computed: {
    veeProps() {
      return {
        email: {
          name: 'メールアドレス',
          rules: { required: true, email: true }
        },

        category: {
          name: 'お問い合わせカテゴリ',
          rules: { required: true }
        },

        body: {
          name: 'お問い合わせ内容',
          rules: { required: true, max: 21845 }
        },

        agreement: {
          name: '利用規約',
          rules: { is: { other: true } },
          customMessages: { is: '利用規約への同意は必須です。' }
        }
      };
    }
  },

  methods: {
    /**
     * フォームの送信
     */
    async post() {
      if (!(await this.$refs.observerRef.validate())) return;

      const payload = {
        mail: this.form.email,
        contact_id: this.form.category,
        content: this.form.body
      };
      this.$emit('post', payload);
    }
  }
};
</script>
