var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "c-section" },
      [
        _c("ValidationObserver", {
          ref: "observerRef",
          staticClass: "c-form",
          attrs: { tag: "form" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (ref) {
                var valid = ref.valid
                return [
                  _c("BaseInput", {
                    attrs: {
                      type: "email",
                      label: "メールアドレス",
                      required: "",
                      placeholder: "メールアドレスを入力してください",
                      "vee-props": _vm.veeProps.email,
                    },
                    model: {
                      value: _vm.form.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email",
                    },
                  }),
                  _c("BaseSelect", {
                    attrs: {
                      label: "お問い合わせカテゴリ",
                      items: _vm.categories,
                      required: "",
                      "vee-props": _vm.veeProps.category,
                    },
                    model: {
                      value: _vm.form.category,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "category", _vm._n($$v))
                      },
                      expression: "form.category",
                    },
                  }),
                  _c("BaseTextarea", {
                    attrs: {
                      label: "お問い合わせ内容",
                      required: "",
                      placeholder: "お問い合わせ内容を入力してください",
                      "vee-props": _vm.veeProps.body,
                    },
                    model: {
                      value: _vm.form.body,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "body", $$v)
                      },
                      expression: "form.body",
                    },
                  }),
                  _c(
                    "BaseCheckbox",
                    {
                      attrs: { "vee-props": _vm.veeProps.agreement },
                      model: {
                        value: _vm.form.agreement,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "agreement", $$v)
                        },
                        expression: "form.agreement",
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.$customUrlScheme.page(
                              "standalone",
                              "url=/terms"
                            ),
                          },
                        },
                        [_vm._v("利用規約")]
                      ),
                      _vm._v(" に同意する "),
                    ]
                  ),
                  _c(
                    "Button",
                    {
                      staticClass: "p-contact__action",
                      attrs: {
                        "style-type": valid ? "secondary" : "disabled",
                        fluid: "",
                      },
                      on: { click: _vm.post },
                    },
                    [_vm._v(" 送信する ")]
                  ),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c-section" }, [
      _c("p", [
        _vm._v("アプリへのご質問、お問い合わせはこちらからお願いいたします。"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }